<template>
  <div class="container">
    <p>
      Vue.js: Simplicidade e Flexibilidade na Reatividade
      Exemplo de um componente Vue que usa reatividade simples com data e computed:
      Exemplo: Reatividade com Two-Way Data Binding
      Neste exemplo, o Vue.js utiliza sua reatividade simples e eficiente, onde mudanças no estado do componente são
      automaticamente refletidas no DOM.
    </p>
    <h1>{{ greeting }}</h1>
    <h2>{{ $t('hello') }}</h2>
    <button @click="changeName">Mudar Nome</button>
  </div>
</template>

<script>
export default {
  name: "SandboxComponent",
  data() {
    return {
      name: 'Vue.js',
    };
  },
  computed: {
    greeting() {
      return `Bem-vindo ao ${this.name}!`;
    },
  },
  methods: {
    changeName() {
      this.name = this.name === 'Mundo Vue' ? 'Vue Mundo' : 'Mundo Vue';
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  h1 {
    color: green;
    font-size: 2rem;
  }

  button {
    margin-top: 20px;
    padding: 10px;
    background-color: lightblue;
    border: none;
    cursor: pointer;
  }
}
</style>