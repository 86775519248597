<template>
  <div>
    <button @click="toggleTheme">
      Alternar para {{ theme === 'light' ? 'Tema Escuro' : 'Tema Claro' }}
    </button>
  </div>
</template>

<script>
import { getInitialTheme, setTheme } from '@/services/ThemeSwicherService';
import { ref, onMounted } from 'vue';

export default {
  name: "ThemeSwitcher",
  setup() {
    const theme = ref(getInitialTheme());

    const toggleTheme = () => {
      theme.value = theme.value === 'light' ? 'dark' : 'light';
      setTheme(theme.value);
    };

    onMounted(() => {
      setTheme(theme.value);
    });

    return {
      theme,
      toggleTheme,
    };
  },
};
</script>