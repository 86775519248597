import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './assets/logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sandbox = _resolveComponent("Sandbox")!
  const _component_ThemeSwitcher = _resolveComponent("ThemeSwitcher")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      alt: "Vue logo",
      src: _imports_0
    }, null, -1)),
    _createVNode(_component_Header),
    _createVNode(_component_Sandbox),
    _createVNode(_component_ThemeSwitcher)
  ], 64))
}