import { createI18n } from 'vue-i18n';

async function loadLocaleMessages(locale: string) {
  const response = await fetch(`/locales/${locale}.json`, {
    method: 'GET',
  });
  const body = await response.json()
  return body;
}

const i18n = createI18n({
  locale: 'pt',
  fallbackLocale: 'en',
  messages: {},
});

loadLocaleMessages(i18n.global.locale).then((messages) => {
  i18n.global.setLocaleMessage(i18n.global.locale, messages);
});

export default i18n;