export function getInitialTheme() {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedTheme = localStorage.getItem('theme');
    if (typeof storedTheme === 'string') {
      return storedTheme;
    }
  }

  return 'light';
}

export function setTheme(theme: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }
}