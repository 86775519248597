<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <Header />
  <Sandbox />
  <ThemeSwitcher />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Header from './components/Header/Header.vue';
import Sandbox from './components/Sandbox/Sandbox.vue';
import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher.vue';
@Options({
  components: {
    Header,
    Sandbox,
    ThemeSwitcher
  },
})
export default class App extends Vue { }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
:root {
  --background-color-light: #ffffff;
  --text-color-light: #000000;
  --background-color-dark: #333333;
  --text-color-dark: #ffffff;
}

[data-theme="light"] {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
}

[data-theme="dark"] {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}
</style>
