import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("span", null, "Ferdinandes", -1),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, "Home"),
      _createElementVNode("li", null, "About"),
      _createElementVNode("li", null, "Works"),
      _createElementVNode("li", null, "Services"),
      _createElementVNode("li", null, "Contact")
    ], -1),
    _createElementVNode("a", { href: "#" }, " Vamos nos conectar ", -1)
  ])))
}