<template>
  <div class="header">
    <span>Ferdinandes</span>


    <ul>
      <li>Home</li>
      <li>About</li>
      <li>Works</li>
      <li>Services</li>
      <li>Contact</li>
    </ul>
    <a href="#">
      Vamos nos conectar
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    msg: String
  }
})
export default class Header extends Vue {
  msg!: string

}
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

ul {
  display: flex;
  list-style: none;
  gap: 1rem;

  li {
    padding: 0;
    margin: 0;
  }
}
</style>
